
import { defineComponent } from "vue";
import PageDetail from "@/views/page/PageDetail.vue";

export default defineComponent({
  name: "Collection",
  components: {PageDetail},
  props: {
    id: {required: true, type: String}
  }
})
