
import { computed, defineComponent, ref, watch } from "vue";
import store from "@/store";
import { LoadEntity, LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Row from "@/components/base/common/Row.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "PageDetail",
  components: { Avatar, Row},
  props: {
    pageId: {required: true, type: String}
  },
  setup(props) {
    const state = ref({title: '', show: false, id: null})
    const model = ref({name: '', type: '', body: '', parentId: '', description: '', status: ''})
    const refId = ref<any>(props.pageId);
    const filterObject = ref({parentId: refId.value, status: 'PUBLISHED'});
    const pageEntity = computed<any>(() => store.state.PageModule.pageEntity)
    const listPage = computed(() => store.state.PageModule.pages)
    watch(() => store.state.PageModule.pageEntity, (cb: any) => {
      console.log(cb);
      let pageTitle = 'Help Center';
      if (cb.data.type === 'FOLDER') {
        pageTitle = 'Help Center';
      }
      const data: any = []
      if (cb.data.collection) {
        data.push({link: true, router: '/help/doc/collection/' + cb.data.collection.id, text: "Help Center"})
      }
      if (cb.data.folder) {
        data.push({link: true, router: '/help/doc/folder/' + cb.data.folder.id, text: cb.data.folder.name})
      }
      data.push({link: false, router: '', text: cb.data.name})
      setCurrentPageBreadcrumbsWithParams(pageTitle, data)
    })
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Created By",
        key: "createdBy",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "date",
        sortable: true,
      }
    ]);
    return {
      ...LoadEntity(Actions.LOAD_PAGE),
      ...LoadFilterObjects(Actions.LOAD_PAGES, filterObject.value, ['createdBy'], 'sort:order:asc'),
      pageEntity,
      tableHeader,
      listPage,
      filterObject,
      refId,
      model,
      state,
    }
  },
  watch: {
    pageId(objectId) {
      this.reloadDataWithId(objectId);
      console.log(objectId);
      this.filterObject.parentId = objectId;
      this.updateFilterObject(this.filterObject)
    }
  },
})
